//@ts-nocheck
import Cookies from 'js-cookie'

import {
  USER_UUID_COOKIE_NAME,
  getRequestUuid,
} from '../../FeaturesAndExperiments/setAndGetUserUUID'
import { getAttributionCookie } from './getAttributionCookie'

export function getQueryParamsEstatePlanning(): string {
  const queryParams = new URLSearchParams()

  const attributionCookie = getAttributionCookie()
  const tomorrowAttributionMap = {
    utmSource: 'utm_source',
    utmCampaign: 'utm_campaign',
    utmMedium: 'utm_medium',
    utmContent: 'utm_content',
    utmTerm: 'utm_term',
    e_ad_id: 'e_ad_id',
    e_adset_id: 'e_adset_id',
    e_campaign_id: 'e_campaign_id',
    fbclid: 'fbclid',
  }
  for (const [cookieKey, cookieValue] of Object.entries(
    tomorrowAttributionMap
  )) {
    const attributionValue = attributionCookie[cookieKey]
    if (attributionValue) {
      queryParams.set(cookieValue, attributionCookie[cookieKey])
    }
  }

  // adds segment anonymous Id to external_id in search param
  if (getRequestUuid()) {
    queryParams.set('external_id', getRequestUuid()!.replace(/"/g, ''))
  }

  // do not append ? to query params if there are attached query params
  return queryParams.toString() === '' ? '' : `?${queryParams.toString()}`
}
