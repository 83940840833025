//@ts-nocheck
import get from 'lodash.get'
import removeMd from 'remove-markdown'

import { PATH_KEYS, TEMPLATE_KEYS } from '../../../constants'

/**
 * PrepareResult preps the content from the results for use in PreviewResult/FullResult
 *
 * @param {Object} page - contains data that will be parsed for display in a search result
 * @param {string} page.pathKey
 * @param {string} page.templateKey
 * @param {string} page.title
 * @param {string} page.description
 * @param {string} page.url
 * @param {Object} page.faqSingle
 * @param {string} page.faqSingle.faqCategory
 * @param {string} page.faqSingle.answer
 *
 * @return {Object} the prepared data for the result
 */

interface PrepareResultProps {
  pathKey: string
  templateKey: string
  title: string
  description: string
  url: string
  faqSingleCategory?: string
  faqSingleAnswer?: string
  faqSingleAnswerPreview?: string
}

interface PrepareResultsReturnProps {
  title: string
  caption: string
  description: string
  url: string
}

export const PrepareResult = (
  page: PrepareResultProps
): PrepareResultsReturnProps => {
  // Prepare the URL for the results page
  let url =
    page.pathKey === PATH_KEYS.ROOT
      ? `/${page.url}/`
      : `/${page.pathKey}/${page.url}/`

  // Define content fields for result snippets
  let caption = page.pathKey.toUpperCase()
  const title = page.title
  let description = removeMd(page.description)

  // Check for single faq page fields
  const isValidSingleFaqPage =
    page.pathKey === PATH_KEYS.FAQ &&
    page.templateKey === TEMPLATE_KEYS.FAQ_SINGLE &&
    page.faqSingleCategory &&
    (page.faqSingleAnswer || page.faqSingleAnswerPreview)

  if (page.templateKey === TEMPLATE_KEYS.FAQ_INDEX) {
    // Manually set caption for /faq since there is no category
    caption = 'Frequently Asked Questions'
  } else if (isValidSingleFaqPage) {
    // Use FAQ categories for FAQ Single captions
    caption = get(page, 'faqSingleCategory', '')
    description =
      get(page, 'faqSingleAnswerPreview') || get(page, 'faqSingleAnswer')
    url = '/' + page.pathKey + '/' + page.url + '/'
  }

  return { title, caption, description, url }
}
