import React, { memo } from 'react'

import { ModuleProvider } from '@/contexts/ModuleContext'
import { CMSLayout } from '@/layouts/CmsLayout'

import { ModulesWrapper } from './ModulesWrapper'
import { PageProps } from './types'

const LifePage = ({
  pageContent,
  settings,
  templateKey,
  ...rest
}: PageProps) => {
  const templateKeyClassName = 'PageTemplate--' + templateKey
  const cmsUrl = `${pageContent.pathKey}/${pageContent.url}`

  return (
    <ModuleProvider moduleData={pageContent}>
      <CMSLayout
        pageContent={pageContent}
        settings={settings}
        agentLayout={false}
        url={cmsUrl}
        footnoteFooterInfo={pageContent.footnoteFooter}
        isAstaLayout={pageContent.isAstaLayout}
      >
        <div className={templateKeyClassName}>
          <ModulesWrapper
            pageContent={pageContent}
            settings={settings}
            rest={rest}
          />
        </div>
      </CMSLayout>
    </ModuleProvider>
  )
}

export default memo(LifePage)
