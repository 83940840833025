import { useEffect } from 'react'

import { getModuleIdClosure } from '@/helpers/keys'

// import { LOCAL_STORAGE } from 'lib/@getethos/constants'
// import { useInView } from 'react-intersection-observer'

import Client from '@/components/global/DynamicComponent/Client'

// import { useLocalStorageStateVisibleModule } from '@/hooks/useLocalStorageVisibleModule'

import { PageProps } from './types'

export const getModulesWithVisibleModuleTrack = (
  pageContent: PageProps['pageContent'],
  settings: PageProps['settings'],
  rest: PageProps['rest']
) => {
  // eslint-disable-next-line
  // useEffect(() => {
  //   setVisibleModules([])
  // }, [])

  // const [_, setVisibleModules, addVisibleModule, removeVisibleModule] =
  // eslint-disable-next-line
  // useLocalStorageStateVisibleModule(LOCAL_STORAGE.VISIBLE_MODULES, [])
  const getModuleId = getModuleIdClosure()

  const modules = pageContent.pageLayout.map(
    (module: { type: string; [key: string]: any }) => {
      const moduleId = getModuleId(module.type)
      // const { ref: myRef } = useInView({
      //   triggerOnce: false,
      //   threshold: 0.5,
      //   onChange: (inView, entry) => {
      //     if (inView && entry.target.innerHTML.length > 0) {
      //       addVisibleModule(moduleId)
      //     } else if (entry.target.innerHTML.length <= 0) {
      //       removeVisibleModule(moduleId)
      //     }
      //   },
      // })
      return (
        <div id={moduleId} key={moduleId}>
          <Client
            tag={module.type}
            key={moduleId}
            moduleData={module}
            settings={settings}
            singlesData={rest.faqSingles}
            isAstaLayout={pageContent.isAstaLayout}
            {...rest}
          />
        </div>
      )
    }
  )
  return modules
}
