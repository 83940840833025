import { memo } from 'react'

import { CMSLayout } from '@/layouts/CmsLayout'

import { getModulesWithVisibleModuleTrack } from './utils'

const FaqIndexPage = ({ pageContent, settings, ...rest }: any) => {
  const cmsUrl = `${pageContent.pathKey}/${pageContent.url}`
  return (
    <CMSLayout
      pageContent={pageContent}
      settings={settings}
      url={cmsUrl}
      footnoteFooterInfo={pageContent.footnoteFooter}
      agentLayout={pageContent.pathKey == 'agents'}
    >
      {getModulesWithVisibleModuleTrack(pageContent, settings, rest)}
    </CMSLayout>
  )
}

export default memo(FaqIndexPage)
