import React from 'react'

/**
 * SearchIcon - The magnifying glass icon used in the SearchIndex text input field
 *
 * @return { ReactElement } An SVG element
 */
export const SearchIcon = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="13.9453"
      cy="13.944"
      r="7.86196"
      stroke="#272727"
      strokeWidth="1.5"
    />
    <path
      d="M19.3063 19.3041L26.6666 25.5876"
      stroke="#272727"
      strokeWidth="1.5"
    />
  </svg>
)
