import React from 'react'

import Link from 'next/link'

import {
  AGENTS_HELP_CENTER_URL,
  HELP_CENTER_URL,
  SLUGIFY_SETTINGS,
} from '@/constants'
import {
  Body,
  COLORS,
  Caption,
  Footnote,
  Layout,
  TitleMedium,
} from 'ethos-design-system'
import get from 'lodash/get'
import shuffle from 'lodash/shuffle'
import slugify from 'slugify'

import { trimFaqQuestionPreview } from '../../helpers/trimFaqQuestionPreview'
import { IconIntegratedTitle } from '../../helpers/words'
import { QuestionCtaArrow } from '../../icons'
import Markdown from '../global/Markdown'
import styles from './FaqSingle.module.scss'

const MAX_BREADCRUMB_TITLE_LENGTH = 23

const trimBreadcrumbTitle = (title: string) => {
  return title.length > MAX_BREADCRUMB_TITLE_LENGTH
    ? title.substring(0, MAX_BREADCRUMB_TITLE_LENGTH) +
        (title.substring(title.length - 1, title.length) !== '?' ? '...' : '')
    : title
}

const prepareRelatedQuestions = ({
  data,
  question,
  category,
  faqType,
}: any) => {
  const related = []
  const similarQuestions = shuffle(data).filter((single) => {
    return (
      get(single, 'faqSingle.faqCategory', '').trim() === category.trim() &&
      get(single, 'faqSingle.question', '').trim() !== question.trim()
    )
  })

  if (similarQuestions.length < 5) {
    const sameFaqTypes = shuffle(data.edges).filter((single) => {
      return (
        get(single, 'faqSingle.faqType', '') === faqType &&
        get(single, 'faqSingle.question', '').trim() !== question.trim()
      )
    })
    related.push(...sameFaqTypes)
  }

  for (const similarQuestion of similarQuestions) {
    if (related.length >= 5) {
      break
    }
    related.push(similarQuestion)
  }

  return related
}

const FaqSingle = ({ moduleData, relatedData }: any) => {
  const { question, answer, faqCategory, faqType } = moduleData
  const relatedQuestions = prepareRelatedQuestions({
    data: relatedData,
    question: question,
    category: faqCategory,
    faqType: faqType,
  })

  const rootClasses = [styles.root, 'FaqSingle__Wrapper']
  const helpCenterUrl =
    faqType === 'consumer' ? HELP_CENTER_URL : AGENTS_HELP_CENTER_URL

  return (
    <Layout.HorizontallyPaddedContainer>
      <div className="DynamicComponent DynamicComponent--FaqSingle">
        <div className={rootClasses.join(' ')}>
          <div className={styles.Breadcrumbs}>
            <Link href={`/${helpCenterUrl}/`}>
              <Caption.Regular400>Help Center</Caption.Regular400>
            </Link>
            <span>&gt;</span>
            <Link
              href={`/${helpCenterUrl}/${slugify(
                faqCategory,
                SLUGIFY_SETTINGS
              )}/`}
            >
              <Caption.Regular400>{faqCategory}</Caption.Regular400>
            </Link>
            <div className={styles.LaptopAndUp}>
              <span>&gt;</span>
              <Caption.Regular400>{question}</Caption.Regular400>
            </div>
            <div className={styles.PhoneAndTablet}>
              <span>&gt;</span>
              <Caption.Regular400>
                {trimBreadcrumbTitle(question)}
              </Caption.Regular400>
            </div>
          </div>
          <div className={styles.Content}>
            <div className={styles.Answer}>
              <div className={styles.Question}>
                <TitleMedium.Sans.Medium500 element="h1">
                  {question}
                </TitleMedium.Sans.Medium500>
              </div>
              <Body.Regular400>
                <Markdown input={answer} />
              </Body.Regular400>
            </div>
            <div className={styles.Related}>
              <Body.Medium500>Related Questions</Body.Medium500>
              <div className={styles.RelatedQuestions}>
                {relatedQuestions.map((related, idx) => (
                  <div
                    className={styles.RelatedQuestion}
                    key={`relatedQuestion${idx}`}
                  >
                    <Link href={`/${get(related, 'pathKey')}/${related.url}/`}>
                      <Footnote.Medium500>
                        <IconIntegratedTitle
                          title={get(related, 'faqSingle.question')}
                        >
                          {QuestionCtaArrow}
                        </IconIntegratedTitle>
                      </Footnote.Medium500>
                      <div className={styles.RelatedAnswer}>
                        <Footnote.Regular400 color={COLORS.GRAY_SECONDARY}>
                          {trimFaqQuestionPreview(
                            get(related, 'faqSingle.faqAnswerPreview') ||
                              get(related, 'faqSingle.answer'),
                            'answer'
                          )}
                        </Footnote.Regular400>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout.HorizontallyPaddedContainer>
  )
}

export default FaqSingle
