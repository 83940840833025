import React, { memo } from 'react'

import { AGENT_PORTAL_EMBEDDED_PAGES } from '@/constants'

import { CMSLayout } from '../layouts/CmsLayout'
import { PageProps } from './types'
import { getModulesWithVisibleModuleTrack } from './utils'

const AgentPage = ({
  pageContent,
  settings,
  templateKey,
  ...rest
}: PageProps) => {
  const isEmbedded = AGENT_PORTAL_EMBEDDED_PAGES.includes(
    `${pageContent.pathKey}/${pageContent.url}`
  )
  const inIFrame = typeof window !== 'undefined' && window.self !== window.top

  const templateKeyClassName = 'PageTemplate--' + templateKey

  const cmsUrl = `${pageContent.pathKey}/${pageContent.url}`

  return (
    <CMSLayout
      pageContent={pageContent}
      settings={settings}
      noNavbar={isEmbedded || pageContent.noNavbar}
      noFooter={isEmbedded}
      noLegalBanner={isEmbedded && inIFrame}
      agentLayout={true}
      shouldLoadAgentsIFrameScript={isEmbedded && inIFrame}
      url={cmsUrl}
      footnoteFooterInfo={pageContent.footnoteFooter}
    >
      <div className={templateKeyClassName}>
        {getModulesWithVisibleModuleTrack(pageContent, settings, rest)}
      </div>
    </CMSLayout>
  )
}

export default memo(AgentPage)
