import React from 'react'

import Link from 'next/link'

import { Body, Spacer } from 'ethos-design-system'

import { searchModuleAnalytics } from '../../../../lib/@getethos/analytics/analyticsEvents'
import { LENGTHS, VARIANTS } from '../constants'

/**
 * TitleResult is a shorter result snippet for baked in module (title only)
 * We use this in what's called the 'Preview' variation of the SearchIndex module
 *
 * @param {string} url - The URL for the result
 * @param {string} title - The title for the result
 * @param {string} query - The search query being searched
 * @param {number} rank - The rank (order) of the result amongst sibling results
 * @return {JSX.Element} A link to the search result wrapping the title of the result
 */
interface TitleResultProps {
  url: string
  title: string
  query: string
  rank: number
}

export const TitleResult = ({
  url,
  title,
  query,
  rank,
}: TitleResultProps): JSX.Element => {
  // Trim and highlight the query in the title if found
  // (case insensitive for capitalized words)
  let resultTitle = title.substring(0, LENGTHS.MAX_TITLE)
  const queryLower = query.toLowerCase()
  const queryCapitalized = queryLower[0].toUpperCase() + queryLower.substring(1)
  if (resultTitle.includes(queryLower)) {
    resultTitle = resultTitle.replace(
      queryLower,
      `<strong>${queryLower}</strong>`
    )
  } else if (resultTitle.includes(queryCapitalized)) {
    resultTitle = resultTitle.replace(
      queryCapitalized,
      `<strong>${queryCapitalized}</strong>`
    )
  }

  // Add ellipsis to end of longer titles if last character is not already '?'
  if (
    resultTitle.length > LENGTHS.MAX_TITLE &&
    resultTitle.substring(resultTitle.length - 1, resultTitle.length) !== '?'
  ) {
    resultTitle += '...'
  }
  const dangerousTitle = { __html: resultTitle }
  const trackTitleResultClick = (): void => {
    searchModuleAnalytics.resultClicked({
      properties: {
        moduleVariation: VARIANTS.PREVIEW,
        searchTerm: query,
        resultRank: rank,
        resultUrl: url,
      },
    })
  }
  return (
    <>
      <Body.Regular400>
        <Link
          href={url}
          // We use dangerouslySetInnerHTML below to allow for the wrapping of <strong> tags
          // within the title text, when a string matching the search query is found
          dangerouslySetInnerHTML={dangerousTitle}
          onClick={trackTitleResultClick}
        />
      </Body.Regular400>
      <Spacer.H8 />
    </>
  )
}
