import React, { memo } from 'react'

import { CMSLayout } from '@/layouts/CmsLayout'

import { PageProps } from './types'
import { getModulesWithVisibleModuleTrack } from './utils'

const MonoPage = ({
  pageContent,
  settings,
  templateKey,
  ...rest
}: PageProps) => {
  const templateKeyClassName = 'PageTemplate--' + templateKey

  // check if page is embedded in an iframe to hide legal banner
  const inIFrame = typeof window !== 'undefined' && window.self !== window.top

  const cmsUrl = `${pageContent.pathKey}/${pageContent.url}`
  return (
    <CMSLayout
      pageContent={pageContent}
      settings={settings}
      noFooter={true}
      noNavbar={true}
      agentLayout={false}
      noLegalBanner={inIFrame}
      url={cmsUrl}
      footnoteFooterInfo={pageContent.footnoteFooter}
    >
      <div className={templateKeyClassName}>
        {getModulesWithVisibleModuleTrack(pageContent, settings, rest)}
      </div>
    </CMSLayout>
  )
}

export default memo(MonoPage)
