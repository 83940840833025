// SearchIndex module variations
export const VARIANTS = {
  FULL: 'full', // unlimited results and content snippets
  PREVIEW: 'preview', // limited results and title only
  SEARCH: 'search', // no results as you type, must be submitted
}

export type VariantsProps = 'full' | 'preview' | 'search'

// module layouts
export const LAYOUTS = {
  TOP: 'alignTop',
  CENTER: 'alignCenter',
}

// string and array lengths
export const LENGTHS = {
  MIN_QUERY: 3, // query the user types
  MAX_PREVIEW_RESULTS: 3, // limited results for PREVIEW variation
  MAX_TITLE: 60, // title string length
  MAX_DESCRIPTION: 160, // description string length for content snippet on FULL variation
}

// Search Module Full variation "home" URL
// TODO: make this a CMS setting in /admin
export const SEARCH_HOME_URL = 'search'
