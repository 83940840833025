import React from 'react'

import Link from 'next/link'

import { CMSLayout } from '@/layouts/CmsLayout'
import { Caption, Spacer, TitleMedium } from 'ethos-design-system'
import get from 'lodash.get'

import FaqQuestionsFeed from '../components/Faq/FaqQuestionsFeed'
import { AGENTS_HELP_CENTER_URL, HELP_CENTER_URL } from '../constants'
import styles from './faq-category-template.module.scss'

const FaqCategoryTemplate = ({ title, questions, faqType }: any) => {
  const helpCenterUrl =
    faqType == 'consumer' ? HELP_CENTER_URL : AGENTS_HELP_CENTER_URL
  const questionsArray = []
  for (const question of questions) {
    questionsArray.push({
      faqQuestionPreview: get(question, 'faqSingle.question'),
      faqAnswerPreview:
        get(question, 'faqSingle.faqAnswerPreview') ||
        get(question, 'faqSingle.answer'),
      url: `/${get(question, 'pathKey')}/${get(question, 'url')}/`,
    })
  }
  return (
    <React.Fragment>
      <div className="DynamicComponent DynamicComponent--FaqCategory">
        <div className="FaqCategory">
          <Spacer.H32 />
          <div className={styles.Breadcrumbs}>
            <Link href={`/${helpCenterUrl}/`}>
              <Caption.Regular400>Help Center</Caption.Regular400>
            </Link>
            <span>&gt;</span>
            <Caption.Regular400>{title}</Caption.Regular400>
          </div>
          <Spacer.H48 />
          <TitleMedium.Serif.Book500>{title}</TitleMedium.Serif.Book500>
          <Spacer.H32 />
          <FaqQuestionsFeed questions={questionsArray} fullWidth />
        </div>
      </div>
    </React.Fragment>
  )
}

const FaqCategoryPage = ({ pageContent, settings, faqSingles }: any) => {
  const cmsUrl = `${pageContent.pathKey}/${pageContent.url}`
  return (
    <CMSLayout
      pageContent={pageContent}
      settings={settings}
      footnoteFooterInfo={pageContent.footnoteFooter}
      url={cmsUrl}
      agentLayout={pageContent.pathKey == 'agents/faq'}
    >
      <FaqCategoryTemplate
        title={pageContent.title}
        questions={faqSingles}
        faqType={pageContent.faqType}
      />
    </CMSLayout>
  )
}

export default FaqCategoryPage
