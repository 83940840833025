import React, { memo } from 'react'

import { CMSLayout } from '@/layouts/CmsLayout'

import { PageProps } from './types'
import { getModulesWithVisibleModuleTrack } from './utils'

const GeneralPage = ({
  pageContent,
  settings,
  templateKey,
  footnoteFooterInfo,
  pathKey,
  url,
  ...rest
}: PageProps) => {
  const templateKeyClassName = 'PageTemplate--' + templateKey
  const cmsUrl = `${pageContent.pathKey}/${pageContent.url}`
  return (
    <CMSLayout
      footnoteFooterInfo={pageContent.footnoteFooter}
      pageContent={pageContent}
      url={cmsUrl}
      settings={settings}
      agentLayout={false}
      isAstaLayout={pageContent.isAstaLayout}
    >
      <div className={templateKeyClassName}>
        {getModulesWithVisibleModuleTrack(pageContent, settings, rest)}
      </div>
    </CMSLayout>
  )
}

export default memo(GeneralPage)
