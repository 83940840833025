//@ts-nocheck
import Cookies from 'js-cookie'

import { USER_UUID_COOKIE_NAME } from '../../FeaturesAndExperiments/setAndGetUserUUID'
import { getAttributionCookie } from './getAttributionCookie'

export function getQueryParamsCalendly(): string {
  const queryParams = new URLSearchParams()
  const trkCookie = getAttributionCookie()

  if (trkCookie['utm_campaign']) {
    queryParams.set('utm_campaign', trkCookie['utm_campaign'])
  }
  if (trkCookie['utm_medium']) {
    queryParams.set('utm_medium', trkCookie['utm_medium'])
  }
  if (trkCookie['utm_source']) {
    queryParams.set('utm_source', trkCookie['utm_source'])
  }
  if (Cookies.get(USER_UUID_COOKIE_NAME)) {
    queryParams.set(
      'salesforce_uuid',
      Cookies.get('USER_UUID')!.replace(/"/g, '')
    )
  }

  return `?${queryParams.toString()}`
}
