import { useModuleContext } from '@/contexts/ModuleContext'
import { getModuleIdClosure } from '@/helpers/keys'

import Client from '@/components/global/DynamicComponent/Client'

import { PageProps } from './types'

interface ModulesWrapperProps {
  pageContent: PageProps['pageContent']
  settings: PageProps['settings']
  rest: PageProps['rest']
}

export const ModulesWrapper = ({
  pageContent,
  settings,
  rest,
}: ModulesWrapperProps) => {
  const getModuleId = getModuleIdClosure()
  const { moduleData } = useModuleContext()

  const modules = moduleData.pageLayout.map((module) => {
    const moduleId = getModuleId(module.type)
    return (
      <div id={moduleId} key={moduleId}>
        <Client
          tag={module.type}
          key={moduleId}
          moduleData={module}
          settings={settings}
          singlesData={rest.faqSingles}
          isAstaLayout={pageContent.isAstaLayout}
          {...rest}
        />
      </div>
    )
  })
  return <>{modules}</>
}
