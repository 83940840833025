import { useEffect, useState } from 'react'

import { useRouter } from 'next/router'

import styles from './DemoSidebar.module.scss'

const EXCLUDED_MODULES = ['SpacerBlock']

export const DemoSidebar = () => {
  const [uniqueModules, setUniqueModules] = useState(new Set())
  const [currentModule, setCurrentModule] = useState('')
  const { push } = useRouter()

  useEffect(() => {
    const parent = document.getElementById('demoModules')
    const children = parent?.children

    if (children) {
      const modules = Array.from(children)
        .filter(
          (child) =>
            child.id && !EXCLUDED_MODULES.includes(child.id.split('-')[0])
        )
        .map((child) => child.id.split('-')[0])

      setUniqueModules(new Set(modules))
    }

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const id = entry.target.id.split('-')[0]
          if (entry.isIntersecting && id && !EXCLUDED_MODULES.includes(id)) {
            setCurrentModule(id)
          }
        })
      },
      { rootMargin: '-50% 0px -50% 0px' }
    )

    if (parent) {
      Array.from(parent.children)
        .filter((child) => child.id && !EXCLUDED_MODULES.includes(child.id))
        .forEach((child) => observer.observe(child))

      return () => {
        Array.from(parent.children).forEach((child) =>
          observer.unobserve(child)
        )
      }
    }
  }, [setCurrentModule])

  useEffect(() => {
    const handleScroll = () => {
      const element = document.getElementById(currentModule)
      if (element) {
        element.scrollIntoView({
          behavior: 'auto',
          block: 'center',
          inline: 'center',
        })
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [currentModule])

  return (
    <div>
      <div className={styles.wrapper}>
        {Array.from(uniqueModules).map((module) => (
          <div className={styles.moduleWrapper} key={`demo-sidebar-${module}`}>
            <button
              id={currentModule === module ? currentModule : ''}
              style={{ color: currentModule === module ? 'red' : 'black' }}
              onClick={() => {
                push(`/demo/${module}`)
              }}
            >
              {module as string}
            </button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              cursor="pointer"
              onClick={() => {
                // scroll to module
                const moduleToSearch = `${module}-1`
                const element = document.getElementById(moduleToSearch)
                if (element) {
                  element.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center',
                  })
                }
              }}
              viewBox="0 0 24 24"
            >
              <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
            </svg>
          </div>
        ))}
      </div>
    </div>
  )
}
