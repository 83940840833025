import { memo } from 'react'

import { CMSLayout } from '@/layouts/CmsLayout'

import FaqSingle from '@/components/Faq/FaqSingle'
import SearchIndex from '@/components/Search/SearchIndex'

import { useGlobalSettings } from '@/hooks/content/useGlobalSettings'

const SINGLE_SEARCH_KEY = 'faq-single-search.md'

export const FaqSingleTemplate = ({
  moduleData,
  relatedData,
  agentLayout,
  singleSearchData,
}: any) => {
  const searchIndexData = {
    ...singleSearchData,
    faqType: moduleData.faqType,
  }
  return (
    <>
      <FaqSingle moduleData={moduleData} relatedData={relatedData} />
      {!agentLayout && <SearchIndex moduleData={searchIndexData} />}
    </>
  )
}

const FaqSinglePage = ({ pageContent, settings, faqSingles }: any) => {
  const cmsUrl = `${pageContent.pathKey}/${pageContent.url}`
  const agentLayout = pageContent.pathKey == 'agents/faq'
  const singleSearchData = useGlobalSettings(SINGLE_SEARCH_KEY, settings)
  return (
    <CMSLayout
      pageContent={pageContent}
      settings={settings}
      url={cmsUrl}
      footnoteFooterInfo={pageContent.footnoteFooter}
      agentLayout={agentLayout}
    >
      <FaqSingleTemplate
        moduleData={pageContent.faqSingle}
        relatedData={faqSingles}
        agentLayout={agentLayout}
        singleSearchData={singleSearchData}
      />
    </CMSLayout>
  )
}

export default memo(FaqSinglePage)
