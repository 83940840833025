import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useRouter } from 'next/router'

import { Index as ElasticIndex } from 'elasticlunr'
import {
  Body,
  CloudinaryImage,
  Footnote,
  Layout,
  Spacer,
  TitleLarge,
  TitleSmall,
} from 'ethos-design-system'
import qs from 'qs'

import { searchModuleAnalytics } from '../../../lib/@getethos/analytics/analyticsEvents'
import { isEnterKeyPress } from '../../helpers/isEnterKeyPress'
import { SearchIcon } from '../global/SearchIcon'
import styles from './SearchIndex.module.scss'
import { FullResult, PrepareResult, TitleResult } from './SearchResults'
import {
  LAYOUTS,
  LENGTHS,
  SEARCH_HOME_URL,
  VARIANTS,
  VariantsProps,
} from './constants'

/**
 * SearchIndex module with results based on FAQ entries
 *
 * @param {Object} moduleData - set by users in `/admin` for this module
 *
 * @return {JSX.Element}
 */

interface SearchIndexProps {
  moduleData: {
    heading: string
    enableH1?: boolean
    displaySubHeading?: boolean
    subHeading?: string
    backgroundColor: string
    placeholder?: string
    imageAlt?: string
    groupImage?: string
    variation: VariantsProps
    faqType?: string
  }
}
// get types of SearchResultsProps by console.log of SearchData
interface SearchResultsProps {
  description: string
  id: string
  metaTitle: string
  pathKey: string
  templateKey: string
  title: string
  url: string
}

interface SearchData {
  query: string
  helpText: string
  searchResults: SearchResultsProps[]
  searchedOnce: boolean
  layout: string
  typingTimeout: number | any
}

const SearchIndex = ({ moduleData }: SearchIndexProps) => {
  const {
    heading,
    displaySubHeading,
    subHeading,
    backgroundColor,
    placeholder,
    variation,
    enableH1,
    imageAlt,
    groupImage,
    faqType,
  } = moduleData
  const { push } = useRouter()
  const [searchIndex, setSearchIndex] = useState<any>(null)
  const elasticIndex = useRef<any>(null)
  const imageRef = useRef<React.ReactNode | null>(null)
  const indexData = '' //useSiteSearchIndex() //TODO: add index Data

  // Set state props for query, searched state, help text and results
  const [searchData, setSearchData] = useState<SearchData>({
    query: '',
    helpText: '',
    searchResults: [],
    searchedOnce: false,
    layout: LAYOUTS.CENTER,
    typingTimeout: 0,
  })

  // Wrap heading in h1 if enabled by editor
  const headingElement = enableH1 ? 'h1' : 'div'

  // Set CSS classes
  const SearchIndexClasses = [
    styles.root,
    styles[backgroundColor],
    styles[variation],
  ]

  // Determine layout alignment class
  if (
    searchData.layout !== LAYOUTS.TOP &&
    variation === VARIANTS.FULL &&
    !searchData.searchedOnce
  ) {
    SearchIndexClasses.push(styles.alignCenter)
  } else {
    SearchIndexClasses.push(styles.alignTop)
  }

  // Query and variation validation conditionals
  const isValidQueryLength = useCallback((queryLength: number) => {
    return queryLength >= LENGTHS.MIN_QUERY
  }, [])

  const isSearchVariation = useCallback(() => {
    return variation === VARIANTS.SEARCH
  }, [variation])

  const isPreviewVariation = useCallback(() => {
    return variation === VARIANTS.PREVIEW
  }, [variation])

  const isValidPreviewQuery = useCallback(() => {
    return (
      isValidQueryLength(searchData.query.length) &&
      isPreviewVariation() &&
      searchData.searchResults.length
    )
  }, [
    isValidQueryLength,
    isPreviewVariation,
    searchData.searchResults,
    searchData.query,
  ])

  useEffect(() => {
    async function fetchFileContent() {
      try {
        const response = await fetch('/elasticlunr.json')
        const text = await response.json()
        setSearchIndex(text)
      } catch (error) {
        console.error('Error fetching file content:', error)
      }
    }

    fetchFileContent()
  }, [])
  // Set index data on initial page load
  useEffect(() => {
    if (elasticIndex.current || !searchIndex) return
    elasticIndex.current = ElasticIndex.load(searchIndex)
  }, [indexData, searchIndex])

  // Prepare image JSX if enabled by editor
  if (!imageRef.current && imageAlt && groupImage && isPreviewVariation()) {
    imageRef.current = (
      <div className={styles.searchImage}>
        <figure>
          <CloudinaryImage
            className={undefined}
            publicId={groupImage}
            alt={imageAlt}
            crop="fill"
            width={[1, 1, 540, 600]}
            height={[1, 1, 384, 426]}
          />
        </figure>
      </div>
    )
  }

  // Send the user from the preview module to the search index home page, passing along a query string
  // Occurs on click of the search icon or pressing of the enter key when focused on search input field
  const sendToSearch = (): void => {
    if (isValidPreviewQuery() || isSearchVariation()) {
      // Could add 'search index page' slug in /admin as page relation widget
      // setting to remove 'search' hardcode here, but also has to match EDS navbar
      if (faqType === 'agents') {
        push(`/agents/search/?query=${searchData.query}`)
      } else {
        push(`/${SEARCH_HOME_URL}/?query=${searchData.query}`)
      }
    }
  }

  // Manage the help text as the user types
  const updateHelpText = useCallback(
    (queryResultsLength: number, queryLength: number, queryTerm: string) => {
      // Pluralize helpText based on number of results
      const resultsText = queryResultsLength > 1 ? 'results' : 'result'
      let updatedHelpText = ''

      // Update helpText
      if (queryResultsLength > 0 && isValidQueryLength(queryLength)) {
        if (variation === VARIANTS.FULL) {
          updatedHelpText = `Showing ${queryResultsLength} ${resultsText} for "${queryTerm}"`
        }
      } else if (queryResultsLength < 1 && isValidQueryLength(queryLength)) {
        updatedHelpText = `No results for "${queryTerm}"`
      } else if (!isValidQueryLength(queryLength) && queryLength > 0) {
        updatedHelpText = 'Please enter a longer search term.'
      }
      return updatedHelpText
    },
    [variation, isValidQueryLength]
  )

  // Update the layout and state of results/helpText
  const updateSearchResults = useCallback(
    (query: string) => {
      let updatedLayout = LAYOUTS.CENTER
      // Check for or create the index
      const queryResults = {
        query,
        // Query the index with search string to get an [] of IDs
        results: elasticIndex.current
          .search(query, { expand: true })
          // Map over each ID and return the full document
          .map(({ ref }: { ref: any }) =>
            elasticIndex.current.documentStore.getDoc(ref)
          )
          // Filter by FAQ type
          .filter((result: any) => result.faqType == faqType),
      }
      // declare a new varaible, queryResultsArr, instead of using queryResults for TS type purpose
      let queryResultsArr = []
      if (isPreviewVariation()) {
        // Trim number of results for preview variation
        queryResultsArr = queryResults.results.slice(
          0,
          LENGTHS.MAX_PREVIEW_RESULTS
        )
      } else if (variation === VARIANTS.FULL) {
        queryResultsArr = queryResults.results
        // For the full variation, re-align layout
        if (queryResultsArr.length >= 1 && isValidQueryLength(query.length)) {
          updatedLayout = LAYOUTS.TOP
        }
      }
      let newHelpText = ''
      if (!isSearchVariation()) {
        newHelpText = updateHelpText(
          queryResultsArr.length,
          query.length,
          query
        )
      }

      return {
        query: query,
        layout: updatedLayout,
        searchedOnce: true,
        helpText: newHelpText,
        searchResults: queryResultsArr,
      }
    },
    [
      isPreviewVariation,
      isSearchVariation,
      elasticIndex,
      variation,
      updateHelpText,
      isValidQueryLength,
      faqType,
    ]
  )

  // Analytics for the terms being searched as the user types or submits
  const trackSearchedTerm = useCallback(
    (searchedTerm: Record<string, any>, queryFromUrl: string) => {
      searchModuleAnalytics.termSearched({
        properties: {
          moduleVariation: variation,
          searchTerm: searchedTerm.query,
          numberOfResults: searchedTerm.searchResults.length,
          triggeredByQueryString: queryFromUrl,
        },
      })
    },
    [variation]
  )

  // Make the magic happen as the user types, searching delayed after last keystroke
  const handleSearch = useCallback(
    (query: string, queryFromUrl: any) => {
      const updatedResults = updateSearchResults(query)

      // clear the timeout if the user is typing faster than 1 character per 500ms
      // only use clearTimeout when its parameter is a number
      if (
        searchData.typingTimeout &&
        typeof searchData.typingTimeout === 'number'
      ) {
        clearTimeout(searchData.typingTimeout)
      }

      // prepare to track searched term after 500ms of user typing inactivity
      const trackingTimeout = setTimeout(() => {
        if (isValidQueryLength(updatedResults.query.length)) {
          trackSearchedTerm(updatedResults, queryFromUrl)
        }
      }, 500)

      setSearchData({
        ...searchData,
        query: updatedResults.query,
        helpText: updatedResults.helpText,
        searchResults: updatedResults.searchResults,
        typingTimeout: trackingTimeout,
        searchedOnce: updatedResults.searchedOnce,
        layout: updatedResults.layout,
      })
    },
    [searchData, trackSearchedTerm, updateSearchResults, isValidQueryLength]
  )

  // When the text input value changes
  const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
    handleSearch((event.target as HTMLInputElement).value, false)
  }

  // When the enter button is pressed
  const handleKeypress = (event: React.FormEvent<HTMLInputElement>): void => {
    if (
      isEnterKeyPress(event) &&
      (isValidPreviewQuery() || isSearchVariation())
    ) {
      // Send user from preview module to help center w/query string
      sendToSearch()
    }
  }

  // Handle initial page load with query string parameter
  useEffect(() => {
    if (
      location.search &&
      variation === VARIANTS.FULL &&
      !searchData.searchedOnce
    ) {
      const queryString = qs.parse(
        location.search.substr(1, location.search.length)
      )
      if (queryString.query) {
        handleSearch(queryString.query as string, true)
      }
    }
  }, [variation, handleSearch, searchData.searchedOnce])

  return (
    <div className={SearchIndexClasses.join(' ')}>
      <Layout.HorizontallyPaddedContainer>
        <div className={styles.searchInner}>
          <div className={styles.searchContent}>
            <div className={styles.text}>
              {isSearchVariation() ? (
                <TitleSmall.Serif.Book500 element={headingElement}>
                  {heading}
                </TitleSmall.Serif.Book500>
              ) : (
                <TitleLarge.Serif.Book500 element={headingElement}>
                  {heading}
                </TitleLarge.Serif.Book500>
              )}
              {displaySubHeading && subHeading && (
                <>
                  <Spacer.H16 />
                  <Body.Regular400>{subHeading}</Body.Regular400>
                </>
              )}
            </div>
            <Spacer.H32 />
            <div className={styles.searchBar}>
              <input
                value={searchData.query}
                type="text"
                onChange={handleChange}
                onKeyPress={handleKeypress}
                placeholder={placeholder}
              />
              <div className={styles.outline} />
              <button className={styles.searchIcon} onClick={sendToSearch}>
                {SearchIcon}
              </button>
            </div>
            <Spacer.H24 />
            {searchData.helpText && (
              <div className={styles.helpText}>
                <Footnote.Regular400>{searchData.helpText}</Footnote.Regular400>
                <Spacer.H24 />
              </div>
            )}
            {!searchData.helpText && variation === VARIANTS.FULL && (
              <div className={styles.helpTextSpacer} />
            )}
            {searchData.searchResults.length > 0 &&
              isValidQueryLength(searchData.query.length) && (
                <>
                  <ul className={styles.results}>
                    {searchData.searchResults.map((page) => {
                      const resultContent = PrepareResult(page)
                      return (
                        <li key={page.id}>
                          {variation === VARIANTS.FULL && (
                            <FullResult
                              url={resultContent.url}
                              title={resultContent.title}
                              caption={resultContent.caption}
                              description={resultContent.description}
                              query={searchData.query}
                              rank={searchData.searchResults.indexOf(page) + 1}
                            />
                          )}
                          {isPreviewVariation() && (
                            <TitleResult
                              url={resultContent.url}
                              title={resultContent.title}
                              query={searchData.query}
                              rank={searchData.searchResults.indexOf(page) + 1}
                            />
                          )}
                        </li>
                      )
                    })}
                  </ul>
                  {isPreviewVariation() && <Spacer.H16 />}
                </>
              )}
          </div>
          {imageRef.current}
        </div>
      </Layout.HorizontallyPaddedContainer>
    </div>
  )
}

export default SearchIndex
