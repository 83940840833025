import React from 'react'

import Link from 'next/link'

import {
  COLORS,
  Caption,
  Footnote,
  Spacer,
  TitleSmall,
} from 'ethos-design-system'

import { searchModuleAnalytics } from '../../../../lib/@getethos/analytics/analyticsEvents'
import styles from '../SearchIndex.module.scss'
import { LENGTHS, VARIANTS } from '../constants'

/**
 * FullResult is a longer result snippet with a caption and description
 * We use this in what's called the 'Full' variation of the SearchIndex module
 *
 * @param {string} url - The URL for the result
 * @param {string} title - The title for the result
 * @param {string} caption - The caption for the result
 * @param {string} description - The description for the result
 * @param {string} query - The search query being searched
 * @param {number} rank - The rank (order) of the result amongst sibling results
 *
 * @return {JSX.Element} A link to the search result wrapping the title of the result
 */

interface FullResultProps {
  url: string
  title: string
  caption?: string
  description: string
  query: string
  rank: number
}

export const FullResult = ({
  url,
  title,
  caption,
  description,
  query,
  rank,
}: FullResultProps): JSX.Element => {
  const trackFullResultClick = (): void => {
    searchModuleAnalytics.resultClicked({
      properties: {
        moduleVariation: VARIANTS.FULL,
        searchTerm: query,
        resultRank: rank,
        resultUrl: url,
      },
    })
  }

  const preparedDescription =
    description.length > LENGTHS.MAX_DESCRIPTION
      ? `${description.substring(0, LENGTHS.MAX_DESCRIPTION)}...`
      : description

  return (
    <>
      <TitleSmall.Sans.Medium500>
        <Link href={url} onClick={trackFullResultClick}>
          {title}
        </Link>
      </TitleSmall.Sans.Medium500>
      {caption && (
        <div className={styles.caption}>
          <Spacer.H4 />
          <Caption.Regular400>{caption}</Caption.Regular400>
        </div>
      )}
      <Spacer.H8 />
      {preparedDescription && (
        <Footnote.Regular400 color={COLORS.GRAY_SECONDARY}>
          {preparedDescription}
        </Footnote.Regular400>
      )}
      <Spacer.H24 />
    </>
  )
}
