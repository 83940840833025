import React from 'react'

import { GetStaticPaths, GetStaticProps } from 'next'

import { handleSlug } from '@/helpers/slug'
import AgentPage from '@/templates/agent-template'
import AppTemplate from '@/templates/app-template'
import AuthorPage from '@/templates/author-template'
import FaqCategoryPage from '@/templates/faq-category-template'
import FaqIndexPage from '@/templates/faq-index-template'
import InsurancePage from '@/templates/insurance-template'
import LifePage from '@/templates/life-template'
import MonoPage from '@/templates/mono-template'
import {
  getAllPathUrls,
  getContent,
  getOptionalContent,
  getSettingsMarkdowns,
} from 'lib/content'
import { generateElasticlunrIndex } from 'lib/elasticlunr'
import { generateSiteMapRepresentation } from 'lib/utils'

import { MetaTags } from '../components/MetaTags'
import DemoPage from '../templates/demo-template'
import FaqSinglePage from '../templates/faq-single-template'
import GeneralPage from '../templates/general-template'
import NotFoundPage from '../templates/not-found-template'

const TEMPLATES = {
  'not-found-template': NotFoundPage,
  'general-template': GeneralPage,
  'agent-template': AgentPage,
  'author-template': AuthorPage,
  'insurance-template': InsurancePage,
  'life-template': LifePage,
  'mono-template': MonoPage,
  'faq-index-template': FaqIndexPage,
  'faq-single-template': FaqSinglePage,
  'faq-category-template': FaqCategoryPage,
  'app-template': AppTemplate,
  'demo-template': DemoPage,
}

export default function Page(params: any) {
  const PageTemplate = getTemplate(params.templateKey)
  if (!PageTemplate) return null

  return (
    <>
      <MetaTags {...params} />
      <PageTemplate {...params} />
    </>
  )
}

const getTemplate = (templateKey: keyof typeof TEMPLATES) => {
  if (
    templateKey.includes('blog-single') ||
    templateKey.includes('blog-category')
  ) {
    return TEMPLATES['general-template']
  }

  return TEMPLATES[templateKey]
}

export const getStaticPaths: GetStaticPaths = async () => {
  const pathUrls = await getAllPathUrls()

  await Promise.all([
    generateSiteMapRepresentation(pathUrls),
    generateElasticlunrIndex(),
  ])
  const urls = pathUrls
    .map((pathUrl) => {
      if (pathUrl.pathKey === 'root') {
        return {
          slug: [pathUrl.url],
        }
      }

      if (pathUrl.pathKey === 'agents/faq') {
        return {
          slug: ['agents', 'faq', pathUrl.url],
        }
      }

      const urlPath = pathUrl.url.split('/')
      if (urlPath) {
        return {
          slug: [pathUrl.pathKey, ...urlPath],
        }
      }
    })
    .concat({
      // slug '' repesents the root path /
      slug: [''],
    })
  return {
    paths: urls.map((url) => {
      return {
        params: {
          slug: url?.slug,
        },
      }
    }),
    fallback: false, // false or "blocking"
  }
}

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const pathUrls = await getAllPathUrls()
  const slug = await handleSlug({ pathUrls, slug: params?.slug })
  const [settings, content] = await Promise.all([
    getSettingsMarkdowns('settings'),
    getContent(slug?.pathKey || '', slug?.url || ''),
  ])
  const templateKey = content.templateKey as keyof typeof TEMPLATES
  const {
    faqSingles,
    trustPilot,
    approvedPolicies,
    modifiedContent,
    authorsBio,
  } = await getOptionalContent(content, settings, templateKey, slug?.url)
  const pageProps = {
    pageContent: JSON.parse(JSON.stringify(modifiedContent)),
    authorsBio,
    settings,
    templateKey,
    faqSingles,
    trustPilot: JSON.parse(JSON.stringify(trustPilot)),
    approvedPolicies,
    pathKey: slug?.pathKey,
    url: slug?.url,
  }
  return {
    props: {
      ...pageProps,
    },
  }
}
